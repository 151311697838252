import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import { Base64 } from "js-base64";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

const Activate = (props) => {
  function setFileName(launcherURL) {
    let { partner, addId } = launcherURL;
    let campain = 2;
    if (partner === null || partner === undefined) {
      let toEncode = `0|${campain}|0`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    } else {
      let toEncode = `0|${campain}|${addId === null ? "0" : addId}`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    }
  }

  return (
    <div className="activate" id="activate">
      <section className="activate_container" id="activate_container">
        <ScrollToTop />
        <div className="container position-relative">
          <div className="row">
            <div className="col">
              <div className="activate_text text-center mb-5">
                <h1>
                  Aby aktywować otrzymany kod wykonaj następujące&nbsp;kroki:
                </h1>
              </div>
              <div className="activate_text mb-5">
                <p>
                  1){" "}
                  <a
                    href={props.launcherURL.launcherURL}
                    className="activate_download"
                    onClick={() => window.gtag("event", "download_launcher")}
                    download={setFileName(props.launcherURL)}
                  >
                    Pobierz launcher
                    <FontAwesomeIcon icon={faWindows} className="ml-2" />
                  </a>{" "}
                  a&nbsp;następnie zainstaluj&nbsp;go na&nbsp;swoim
                  komputerze&nbsp;PC
                </p>
                <p>
                  2) Załóż darmowe konto Prime&nbsp;Bit&nbsp;Games
                  i&nbsp;zaloguj się
                </p>
                <p>
                  3) Kliknij na swój login w prawym górnym rogu
                  i&nbsp;z&nbsp;menu wybierz opcję <em>Zrealizuj kod</em>
                </p>
                <p>
                  4) Dalej postępuj zgodnie z&nbsp;instrukcjami na&nbsp;ekranie
                </p>
                <p>
                  W razie problemów napisz do nas na{" "}
                  <a
                    href="https://support.primebitgames.com/open.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support.primebitgames.com
                  </a>{" "}
                  lub wyślij nam maila{" "}
                  <a href="mailto:support@primebitgames.com">
                    support@primebitgames.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Activate;
