import React from "react";
import ScrollToTop from "../components/ScrollToTop";

const Publish = (props) => {
  return (
    <div className="publish" id="publish">
      <section className="publish_container" id="publish_container">
        <ScrollToTop />
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col">
              <div className="publish_text text-center mb-5">
                <h1>
                  {props.lang === "pl"
                    ? "Opublikuj swoją grę na Prime Bit Store"
                    : "Publish your game on Prime Bit Store"}
                </h1>
              </div>
              <div className="publish_text text-center mb-4">
                {props.lang === "pl"
                  ? "Napisz lub zadzwoń do nas ze swoja propozycją a my skontaktujemy się z Tobą:"
                  : "Write or call us with your proposal and we will contact you:"}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="publish_address publish_text text-center mb-5">
                <p className="mb-0">
                  <a href="mailto:office@primebitgames.com">
                    office@primebitgames.com
                  </a>
                </p>
                <p className="mb-0">
                  <a href="tel:+48 17 28 31 870">+48 17 28 31 870</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Publish;
