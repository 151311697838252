import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faYoutubeSquare,
  faInstagramSquare,
  faLinkedin,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faUpload } from "@fortawesome/free-solid-svg-icons";


import UE_PL from "../images/support/ue_combined_pl.png";
import UE_EN from "../images/support/ue_combined_en.png";

const Footer = ({ lang }) => {
  return (
    <div className="footer" id="footer">
      <section>
        <div className="container-fluid">
          <div className="row footer_info my-2">
            <div className="col-12 col-sm-6 d-flex flex-column text-center text-sm-left px-3 px-lg-0 pb-3">
              <div className="footer_social">
                <a
                  href="https://www.facebook.com/PrimeBitGamesSA/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookSquare} className="" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCJAAt1OhFRci-lAVDQGGpiQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faYoutubeSquare} className="" />
                </a>
                <a
                  href="https://www.instagram.com/primebit_games/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagramSquare} className="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/primebit-games-sa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedin} className="" />
                </a>
                <a
                  href="https://twitter.com/PrimeBitGames"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitterSquare} className="" />
                </a>
              </div>
              <span>
                <a
                  href="https://goo.gl/maps/doeL3iuaXXets9uC9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Szlachecka 1, os. Przybyszówka (Dworzysko)
                </a>
              </span>
              <span>
                <a
                  href="https://goo.gl/maps/doeL3iuaXXets9uC9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  35-213 Rzeszów
                </a>
              </span>
              <span>
                <a href="mailto:office@primebitgames.com">
                  office@primebitgames.com
                </a>
              </span>
              <span>
                <a href="tel:+48 17 28 31 870">+48 17 28 31 870</a>
              </span>
              <span className="my-2">
                <NavLink to="/publish" className="footer_page-link my-2">
                  {lang === "pl" ? "Opublikuj swoją grę" : "Publish your game"}
                  <FontAwesomeIcon icon={faUpload} className=" ml-2" />
                </NavLink>
              </span>
              <span style={{ color: "#fff" }}>
                <a
                  href="https://primebitstore.com/terms/terms.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lang === "pl" ? "Regulamin " : "Regulations "}
                </a>
                {lang === "pl" ? "i" : "and"}
                <a
                  href="https://primebitstore.com/terms/privacy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lang === "pl" ? " Polityka prywtności" : " Privacy Policy"}
                </a>
              </span>
            </div>
            <div className="col-12 col-sm-6 text-center px-3 px-lg-0" style={{maxWidth: '600px'}}>
              <div>
                {lang === "pl" ? "Projekt wykonany przy wsparciu finansowym:" : "Project made with financial support:"}
              </div>
              <img className="my-2" src={lang === "pl" ? UE_PL : UE_EN} alt="" style={{width:'100%'}} />
              <div style={{fontSize: '0.8em'}}>
                „Wdrożenie innowacyjnego modelu biznesowego w Prime Bit Games celem wejścia na nowe rynki zagraniczne”, w ramach Programu Operacyjnego Polska Wschodnia na lata 2014-2020, Oś Priorytetowa I Przedsiębiorcza Polska Wschodnia, Działanie 1.2: Internacjonalizacja MŚP, współfinansowanego ze środków Europejskiego Funduszu Rozwoju Regionalnego.
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
