import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import { Helmet } from "react-helmet";

const Error = ({ lang }) => {
  return (
    <section>
      {/* <Helmet>
        <title>
          The page you are looking for was not found.
        </title>
      </Helmet> */}
      <div className="container my-5">
        <div className="row py-5">
          <div className="col">
            <p className="error-page text-center">
              {lang === "pl" ? "Przepraszamy" : "Oops!"}
            </p>
            <p className="error-page text-center">
              {lang === "pl"
                ? "Strona o podanym adresie nie istnieje."
                : "The page you are looking for was not found."}
            </p>
          </div>
          <div className="col-12 text-center">
            <NavLink
              to={{
                pathname: `/`,
              }}
              className="error-page_link"
            >
              {lang === "pl" ? "Strona główna" : "Home"}
              <FontAwesomeIcon icon={faArrowRight} />
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
