import React from "react";
import { NavLink } from "react-router-dom";
import { Base64 } from "js-base64";

const GameCard = ({
  lang,
  game,
  news,
  bundledGames,
  launcherURL,
  currency,
}) => {
  function setCurrencyType() {
    if (currency === "PLN") {
      return "zł";
    } else if (currency === "USD") {
      return "$";
    } else if (currency === "GBP") {
      return "£";
    } else if (currency === "EUR") {
      return "€";
    }
  }

  function setFileName(launcherURL, game_id) {
    let { partner, addId } = launcherURL;
    if (partner === null || partner === undefined) {
      let toEncode = `${game_id}|0|0`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    } else {
      let toEncode = `${game_id}|${partner}|${addId === null ? "0" : addId}`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    }
  }

  function checkPriceFormat(price) {
    if (!price.toString().includes(".")) {
      return null
    } else {
      if (price.toString().split(".")[1].length !== 1) {
        return null
      } else {
        return "0"
      }
    }
  }

  return (
    <div className="col p-0 game-card mb-4">
      <NavLink
        to={{
          pathname: `/game/${game.tag}`,
          state: { lang, game, news, bundledGames, launcherURL },
        }}
        // onClick={window.gtag('event', 'game_view', { 'event_category': "game", 'event_label': game.tag })}
        onClick={() => window.gtag("event", game.tag)}
      >
        <img className="game-card-image" src={game.banner} alt="" />
      </NavLink>
      <h4 className="game-card-title my-2">{game.title}</h4>
      {game.desktop && (
        <>
          <a
            href={launcherURL.launcherURL}
            className="nav-link game-card-btn mr-1"
            onClick={() => window.gtag("event", "download_launcher")}
            download={setFileName(launcherURL, game.id)}
          >
            {game.preorder
              ? "Pre-order"
              : lang === "pl"
              ? "Kup teraz"
              : "Buy now"}
          </a>
          {game.discount !== 0 ? (
            <span className="game-card-discount mr-1">
              {"-" + game.discount + "%"}
            </span>
          ) : null}
          {game.discount !== undefined &&
            game.discount !== 0 &&
            game.full_prices && (
              <span className="game-card-full_price mr-1">
                {currency !== "PLN" ? setCurrencyType() : null}
                {game.full_prices[`${currency}`]}{checkPriceFormat(game.full_prices[`${currency}`])}&nbsp;
                {currency === "PLN" ? setCurrencyType() : null}
              </span>
            )}
          {game.prices && (
            <span className="game-card-price">
              {currency !== "PLN" ? setCurrencyType() : null}
              {game.prices[`${currency}`]}{checkPriceFormat(game.prices[`${currency}`])}&nbsp;
              {currency === "PLN" ? setCurrencyType() : null}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default GameCard;
