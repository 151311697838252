import React from "react";

const FetchError = ({ lang, error }) => {
  return (
    <div className="container my-5">
      <div className="row py-5">
        <div className="col">
          <p className="error-page text-center">
            {lang === "pl" ? "Przepraszamy" : "Oops!"}
          </p>
          <p className="error-page text-center">
            {lang === "pl" ? "Coś poszło nie tak." : "Something went wrong."}
          </p>
          <p className="error-page text-center">
            {lang === "pl" ? "Spróbuj ponownie później." : "Try again later."}
          </p>
          {/* <p className="error-page text-center">{error.message}</p> */}
        </div>
      </div>
    </div>
  );
};

export default FetchError;
