import React from "react";
import { Redirect } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import Slide from "../components/Slide";
import GameCard from "../components/GameCard";
import { Base64 } from "js-base64";
// import { Helmet } from "react-helmet";

import promo_banner from "../images/promo_banner_2.jpg";
import promo_banner_sm from "../images/promo_banner_9.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Games = (props) => {
  const { lang, games, news, launcherURL, currency } = props
  function hasBundledGames(bundle) {
    let bundledGames = [];
    bundle.forEach((item) => {
      games.forEach((game) => {
        if (game.id === item) {
          bundledGames.push(game);
        }
      });
    });
    return bundledGames;
  }

  if (props.location.state) {
    if (props.location.state.referrer) {
      // console.log(props.location.state.referrer, 'ok');
      const game = games.find(game => game.tag === props.location.state.referrer);
      // console.log(game);
      return (
        <Redirect
          to={{
            pathname: `/game/${game.tag}`,
            state: { 
              bundledGames: game.bundled_games.length > 0
                ? hasBundledGames(game.bundled_games)
                : null,
              lang: {lang},
              game: {game},
              launcherURL: {launcherURL},
              currency: {currency},
              redirected: true
             },
          }}
        />
      );
    }
  } 

  function setFileName(launcherURL) {
    let { partner, addId } = launcherURL;
    if (partner === null || partner === undefined) {
      let toEncode = `0|0|0`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    } else {
      let toEncode = `0|${partner}|${addId === null ? "0" : addId}`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    }
  }

  return (
    <div className="games" id="games">
      <section className="games_free-codes">
        <div className="container-fluid">
          <div className="row">
            <a
              href={launcherURL.launcherURL}
              className="nav-link download-launcher-nav-link p-0"
              onClick={() => window.gtag("event", "download_launcher")}
              download={setFileName(launcherURL)}
            >
              <div className="col position-relative games_free-codes__banner p-0">
                <img
                  src={promo_banner}
                  alt="promo banner "
                  className="d-none d-md-block"
                />
                <img
                  src={promo_banner_sm}
                  alt="promo banner "
                  className="d-block d-md-none"
                />
                <p className="m-0">
                  <span>
                    {lang === "pl" ? (
                      <span>
                        Zarejestruj&nbsp;się
                        i&nbsp;otrzymaj&nbsp;jedną&nbsp;losową&nbsp;grę&nbsp;za&nbsp;darmo!
                      </span>
                    ) : (
                      <span>
                        Sign&nbsp;up
                        and&nbsp;get&nbsp;one&nbsp;random&nbsp;game&nbsp;for&nbsp;free!
                      </span>
                    )}
                  </span>
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section className="games_swiper" id="games_swiper">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              {
                <Swiper
                  id="main"
                  tag="div"
                  wrapperTag="ul"
                  navigation
                  pagination={{
                    clickable: true,
                    // type: "bullets",
                    dynamicBullets: true,
                    dynamicMainBullets: 1,
                  }}
                  spaceBetween={0}
                  slidesPerView={1}
                  speed={600}
                  initialSlide={4}
                  loop={true}
                  allowTouchMove={true}
                  breakpoints={{
                    768: {
                      allowTouchMove: false,
                    },
                  }}
                  autoplay={{ delay: 15000, disableOnInteraction: false }}
                  // onInit={(swiper) => console.log(swiper.slides.length)}
                  // onSlideChange={(swiper) =>
                  //   console.log("Slide index changed to: ", swiper.activeIndex)
                  // }
                  // onReachEnd={(swiper) => console.log("Swiper end reached")}
                >
                  {games.map((game) => {
                    if (!game.desktop) {
                      return null;
                    } else {
                      return (
                        <SwiperSlide
                          key={game.id}
                          tag="li"
                          style={{ listStyle: "none" }}
                          index={game.id}
                        >
                          <Slide
                            lang={lang}
                            game={game}
                            launcherURL={launcherURL}
                            news={
                              news.filter((item) => item.tag === game.tag)
                                .length === 0
                                ? null
                                : news.filter((item) => item.tag === game.tag)
                            }
                            bundledGames={
                              game.bundled_games.length > 0
                                ? hasBundledGames(game.bundled_games)
                                : null
                            }
                          />
                        </SwiperSlide>
                      );
                    }
                  })}
                </Swiper>
              }
            </div>
          </div>
        </div>
      </section>
      <section className="games_cards" id="games_cards">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <h2 className="section-heading mt-5 mb-4">
                {lang === "pl" ? "Dla PC" : "For desktop"}
              </h2>
            </div>
          </div>
          <div className="row game-card-container">
            {games.map((game) => {
              if (game.desktop) {
                return (
                  <GameCard
                    key={game.id}
                    lang={lang}
                    game={game}
                    launcherURL={launcherURL}
                    currency={currency}
                    // isRegistered={isRegistered}
                    // isPartnerActive={isPartnerActive}
                    news={
                      news.filter((item) => item.tag === game.tag).length === 0
                        ? null
                        : news.filter((item) => item.tag === game.tag)
                    }
                    bundledGames={
                      game.bundled_games.length > 0
                        ? hasBundledGames(game.bundled_games)
                        : null
                    }
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <h2 className="section-heading mt-2 mb-4">
                {lang === "pl" ? "Tylko mobilne" : "Only mobile"}
              </h2>
            </div>
          </div>
          <div className="row game-card-container">
            {games.map((game) => {
              if (!game.desktop) {
                if (game.pl_only && lang !== "pl") {
                  return null;
                } else {
                  return (
                    <GameCard
                      key={game.id}
                      lang={lang}
                      game={game}
                      launcherURL={launcherURL}
                      currency={currency}
                      news={
                        news.filter((item) => item.tag === game.tag).length ===
                        0
                          ? null
                          : news.filter((item) => item.tag === game.tag)
                      }
                      bundledGames={
                        game.bundled_games.length > 0
                          ? hasBundledGames(game.bundled_games)
                          : null
                      }
                    />
                  );
                }
              }
              return null;
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Games;
