import React, { useState, useEffect } from "react";
import "./Slide.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

const GameDescSlide = ({ media, title }) => {
  const [isTrailerPlayed, setIsTrailerPlayed] = useState(false);

  useEffect(() => {
    if (media.type === "video" && media.file) {
      setIsTrailerPlayed(true);
    }
  }, [media.type, media.file]);

  return (
    <div className="game_desc-slide-container mt-4">
      {media.type === "video" && (
        <div className="embed-responsive embed-responsive-16by9 position-relative">
          {isTrailerPlayed && (
            <video autoPlay="autoplay" muted>
              <source src={media.file} type="video/mp4" />
            </video>
          )}
          {isTrailerPlayed && (
            <div className="play-btn position-absolute">
              <FontAwesomeIcon
                icon={faPlay}
                className=""
                onClick={() => setIsTrailerPlayed(false)}
              />
            </div>
          )}
          {!isTrailerPlayed && (
            <iframe allow="fullscreen;"
              src={`https://www.youtube.com/embed/${media.videoId}?controls=1`}
              title={title}
              className="embed-responsive-item"
            ></iframe>
          )}
        </div>
      )}
      {media.type !== "video" && (
        <div>
          <img src={media.file} alt={title} className="image img-fluid" />
        </div>
      )}
    </div>
  );
};

export default GameDescSlide;
