import React from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { Base64 } from "js-base64";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

import logo from "../images/pbg_logo.png";

const Navigation = (props) => {
  $(".navbar-nav>li>a").on("click", function () {
    $(".navbar-collapse").collapse("hide");
  });
  $(".navbar-brand").on("click", function () {
    $(".navbar-collapse").collapse("hide");
  });

  function setFileName(launcherURL) {
    let { partner, addId } = launcherURL;
    if (partner === null || partner === undefined) {
      let toEncode = `0|0|0`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    } else {
      let toEncode = `0|${partner}|${addId === null ? "0" : addId}`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    }
  }
  return (
    <nav className="navbar fixed-top navbar-expand-md py-0">
      <NavLink to="/" exact className="navbar-brand py-0">
        <img src={logo} alt="PrimeBit Games Logo" />
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler_bar bar1"></span>
        <span className="navbar-toggler_bar bar2"></span>
        <span className="navbar-toggler_bar bar3"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <NavLink to="/" exact className="nav-link" activeClassName="active">
              {props.lang === "pl" ? "Gry" : "Games"}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/news"
              exact
              className="nav-link"
              activeClassName="active"
            >
              {props.lang === "pl" ? "Wiadomości" : "News"}
            </NavLink>
          </li>
          <li className="nav-item mr-md-auto">
            <a
              href="https://forum.primebitgames.com/"
              className="nav-link"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => window.gtag("event", "forum_click")}
            >
              {props.lang === "pl" ? "Społeczność" : "Community"}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1 ml-lg-2" />
            </a>
          </li>
          <li className="nav-item dropdown mr-2">
            <span
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.lang}
            </span>
            <div
              className="dropdown-menu mb-2"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <span
                className="dropdown-item"
                href="#"
                onClick={() => props.setLanguage("pl")}
              >
                PL
              </span>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => props.setLanguage("en")}
              >
                EN
              </span>
            </div>
          </li>
          <li className="nav-item dropdown mr-2">
            <span
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownCurrency"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.currency}
            </span>
            <div
              className="dropdown-menu mb-2"
              aria-labelledby="navbarDropdownCurrency"
            >
              <span
                className="dropdown-item"
                href="#"
                onClick={() => props.setCurrency("PLN")}
              >
                PLN
              </span>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => props.setCurrency("USD")}
              >
                USD
              </span>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => props.setCurrency("GBP")}
              >
                GBP
              </span>
              <span
                className="dropdown-item"
                href="#"
                onClick={() => props.setCurrency("EUR")}
              >
                EUR
              </span>
            </div>
          </li>
          {/* {!props.isRegistered && props.isPartnerActive && (
            <li className="nav-item download-launcher-nav">
              <NavLink to="/register" className="nav-link download-launcher-nav-link">
                {props.lang === "pl" ? "Zarejestruj się" : "Register"}
              </NavLink>
            </li>
          )} */}
          <li className="nav-item download-launcher-nav mr-md-1 mr-lg-2">
            <a
              href={props.launcherURL.launcherURL}
              className="nav-link download-launcher-nav-link"
              onClick={() => window.gtag("event", "download_launcher")}
              download={setFileName(props.launcherURL)}
            >
              {props.lang === "pl" ? "Pobierz gry" : "Get games"}
              <FontAwesomeIcon icon={faWindows} className="ml-2" />
            </a>
          </li>
          <li className="nav-item dropdown help-nav-link">
            <span
              className="nav-link dropdown-toggle py-0"
              href="#"
              id="navbarDropdownInfo"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="" />
            </span>
            <div
              className="dropdown-menu mb-2"
              aria-labelledby="navbarDropdownInfo"
            >
              <div className="dropdown-item-text help-nav-link-content">
                <p className="mb-0">
                  {props.lang === "pl"
                    ? "W razie problemów napisz do nas na: "
                    : "In case of any problems, write to us at: "}
                  <a
                    href="https://support.primebitgames.com/open.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support.primebitgames.com
                  </a>
                  <br />
                  {props.lang === "pl"
                    ? " lub wyślij nam maila: "
                    : " or send us an email: "}
                  <a href="mailto:support@primebitgames.com">
                    support@primebitgames.com
                  </a>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
