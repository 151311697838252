import React from "react";
import { NavLink } from "react-router-dom";
import "./Slide.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import google from "../images/stores/logo-google.png";
import apple from "../images/stores/logo-apple.png";
import nintendo from "../images/stores/logo-nintendo.png";
import xbox from "../images/stores/logo-xbox.png";
import huawei from "../images/stores/logo-huawei.png";
import steam from "../images/stores/logo-steam.png";
import gog from "../images/stores/logo-gog.png";

const Slide = ({ lang, game, news, bundledGames, launcherURL }) => {
  function getDescription(lng = lang) {
    return game.description.filter((item) => item.lang === lng);
  }

  function createMarkup(num = 100, lng = lang) {
    const textArray = getDescription(lng);
    return {
      __html: textArray[0].text.slice(0, num) + "...",
    };
  }

  function openStore(url) {
    window.open(url, "_blank");
  }

  return (
    <div className="slide-container">
      <div className="slide-container-graphic position-relative">
        <NavLink
          to={{
            pathname: `/game/${game.tag}`,
            state: { lang, game, news, bundledGames, launcherURL },
          }}
          onClick={() => window.gtag("event", game.tag)}
          className="nav-link position-relative p-0"
        >
          <img
            src={game.background2}
            alt="Background"
            className="slide-container-bg"
          />
          <img
            src={game.logo}
            alt="Logo"
            className="slide-container-logo position-absolute"
            data-logotag={game.tag}
          />
        </NavLink>
      </div>
      <div className="slide-container-info position-relative">
        <p className="game-title">{game.title}</p>
        <div className="game-desc">
          {!Boolean(getDescription()[0]) && (
            <>
              <div
                dangerouslySetInnerHTML={createMarkup(100, "en")}
                className="game-desc_text d-block d-sm-none"
              ></div>
              <div
                dangerouslySetInnerHTML={createMarkup(180, "en")}
                className="game-desc_text d-none d-sm-block d-md-none"
              ></div>
              <div
                dangerouslySetInnerHTML={createMarkup(130, "en")}
                className="game-desc_text d-none d-md-block d-lg-none"
              ></div>
              <div
                dangerouslySetInnerHTML={createMarkup(160, "en")}
                className="game-desc_text d-none d-lg-block d-xl-none"
              ></div>
              <div
                dangerouslySetInnerHTML={createMarkup(340, "en")}
                className="game-desc_text d-none d-xl-block"
              ></div>
            </>
          )}
          {Boolean(getDescription()[0]) && (
            <>
              <div
                dangerouslySetInnerHTML={createMarkup(100, lang)}
                className="game-desc_text d-block d-sm-none"
              ></div>
              <div
                dangerouslySetInnerHTML={createMarkup(180, lang)}
                className="game-desc_text d-none d-sm-block d-md-none"
              ></div>
              <div
                dangerouslySetInnerHTML={createMarkup(130, lang)}
                className="game-desc_text d-none d-md-block d-lg-none"
              ></div>
              <div
                dangerouslySetInnerHTML={createMarkup(160, lang)}
                className="game-desc_text d-none d-lg-block d-xl-none"
              ></div>
              <div
                dangerouslySetInnerHTML={createMarkup(340, lang)}
                className="game-desc_text d-none d-xl-block"
              ></div>
            </>
          )}
        </div>
        <NavLink
          to={{
            pathname: `/game/${game.tag}`,
            state: { lang, game, news, bundledGames, launcherURL },
          }}
          onClick={() => window.gtag("event", game.tag)}
          className="nav-link"
        >
          {lang === "pl" ? "Dowiedz sie więcej" : "Learn more"}
          <FontAwesomeIcon icon={faArrowRight} className="nav-link-icon" />
        </NavLink>
        {!game.google_url &&
        !game.apple_url &&
        !game.nintendo_url &&
        !game.huawei_url &&
        !game.steam_url &&
        !game.gog_url &&
        !game.xbox_url ? null : (
          <>
            <div className="row slide-container-available position-absolute">
              <div className="col d-flex flex-wrap-reverse slide-container-available--img justify-content-end">
                {game.google_url && (
                  <img
                    src={google}
                    alt="Google Play"
                    className="ml-1 mt-1"
                    onClick={() => openStore(game.google_url)}
                  />
                )}
                {game.apple_url && (
                  <img
                    src={apple}
                    alt="Apple Store"
                    className="ml-1 mt-1"
                    onClick={() => openStore(game.apple_url)}
                  />
                )}
                {game.huawei_url && (
                  <img
                    src={huawei}
                    alt="AppGallery"
                    className="ml-1 mt-1"
                    onClick={() => openStore(game.huawei_url)}
                  />
                )}
                {game.nintendo_url && (
                  <img
                    src={nintendo}
                    alt="Nintendo Switch"
                    className="ml-1 mt-1"
                    onClick={() => openStore(game.nintendo_url)}
                  />
                )}
                {game.xbox_url && (
                  <img
                    src={xbox}
                    alt="XBOX"
                    className="ml-1 mt-1"
                    onClick={() => openStore(game.xbox_url)}
                  />
                )}
                {game.steam_url && (
                  <img
                    src={steam}
                    alt="Steam"
                    className="ml-1 mt-1"
                    onClick={() => openStore(game.steam_url)}
                  />
                )}
                {game.gog_url && (
                  <img
                    src={gog}
                    alt="Gog"
                    className="ml-1 mt-1"
                    onClick={() => openStore(game.gog_url)}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Slide;
