import React from "react";
// import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
// import { Helmet } from "react-helmet";
import { Base64 } from "js-base64";

import GameDescSlide from "../components/GameDescSlide";
import ScrollToTop from "../components/ScrollToTop";

import google from "../images/stores/logo-google.png";
import apple from "../images/stores/logo-apple.png";
import nintendo from "../images/stores/logo-nintendo.png";
import xbox from "../images/stores/logo-xbox.png";
import huawei from "../images/stores/logo-huawei.png";
import steam from "../images/stores/logo-steam.png";
import gog from "../images/stores/logo-gog.png";

import adults from "../images/ratings/esrb-adults.png";
import everyone from "../images/ratings/esrb-everyone.png";
import everyone10 from "../images/ratings/esrb-everyone10.png";
import mature from "../images/ratings/esrb-mature.png";
import teen from "../images/ratings/esrb-teen.png";

import pegi3 from "../images/ratings/pegi-3.jpg";
import pegi7 from "../images/ratings/pegi-7.jpg";
import pegi12 from "../images/ratings/pegi-12.jpg";
import pegi16 from "../images/ratings/pegi-16.jpg";
import pegi18 from "../images/ratings/pegi-18.jpg";

import pegi_drugs from "../images/ratings/pegi-drugs.jpg";
import pegi_fear from "../images/ratings/pegi-fear.jpg";
import pegi_gambling from "../images/ratings/pegi-gambling.jpg";
import pegi_language from "../images/ratings/pegi-language.jpg";
import pegi_online from "../images/ratings/pegi-online.jpg";
import pegi_sex from "../images/ratings/pegi-sex.jpg";
import pegi_violence from "../images/ratings/pegi-violence.jpg";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Game = (props) => {
  if (!props.location.state) {
    const gameTag = props.match.params.gameTag;
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { referrer: gameTag },
        }}
      />
    );
  }
  let game
  let bundledGames
  let lang
  let launcherURL
  let currency

  if (props.location.state.redirected) {
    game = props.location.state.game.game
    bundledGames = props.location.state.bundledGames
    lang = props.location.state.lang.lang
    launcherURL = props.location.state.launcherURL.launcherURL
    currency = props.location.state.currency.currency
  } else {
    game = props.location.state.game;
    bundledGames = props.location.state.bundledGames;
    lang = props.lang;
    launcherURL = props.launcherURL;
    currency = props.currency;
  }

  // const { game, news, bundledGames } = props.location.state;
  // const { game, bundledGames } = props.location.state;
  // const lang = props.lang;
  // const launcherURL = props.launcherURL;
  // const currency = props.currency;

  function getDescription(lng = lang) {
    return game.description.filter((item) => item.lang === lng);
  }
  // const description = game.description.filter((item) => item.lang === lang);

  function createMarkup(lng = lang) {
    const description = getDescription(lng);
    return {
      __html: description[0].text,
    };
  }
  function createMarkupExtra(lng = lang) {
    const description = getDescription(lng);
    return {
      __html: description[0].extra,
    };
  }

  function esrbSwitch(esrb) {
    switch (esrb) {
      case "adults":
        return <img src={adults} alt="esbr" />;
      case "everyone":
        return <img src={everyone} alt="esbr" />;
      case "everyone10":
        return <img src={everyone10} alt="esbr" />;
      case "mature":
        return <img src={mature} alt="esbr" />;
      case "teen":
        return <img src={teen} alt="esbr" />;
      default:
        return null;
    }
  }

  function pegiSwitch(pegi) {
    switch (pegi) {
      case "pegi3":
        return <img src={pegi3} alt="pegi" />;
      case "pegi7":
        return <img src={pegi7} alt="pegi" />;
      case "pegi12":
        return <img src={pegi12} alt="pegi" />;
      case "pegi16":
        return <img src={pegi16} alt="pegi" />;
      case "pegi18":
        return <img src={pegi18} alt="pegi" />;
      default:
        return null;
    }
  }

  function pegiDescSwitch(pegi_descriptors) {
    switch (pegi_descriptors) {
      case "drugs":
        return (
          <img src={pegi_drugs} alt="pegi_descriptors" key={pegi_descriptors} />
        );
      case "fear":
        return (
          <img src={pegi_fear} alt="pegi_descriptors" key={pegi_descriptors} />
        );
      case "gambling":
        return (
          <img
            src={pegi_gambling}
            alt="pegi_descriptors"
            key={pegi_descriptors}
          />
        );
      case "language":
        return (
          <img
            src={pegi_language}
            alt="pegi_descriptors"
            key={pegi_descriptors}
          />
        );
      case "online":
        return (
          <img
            src={pegi_online}
            alt="pegi_descriptors"
            key={pegi_descriptors}
          />
        );
      case "sex":
        return (
          <img src={pegi_sex} alt="pegi_descriptors" key={pegi_descriptors} />
        );
      case "violence":
        return (
          <img
            src={pegi_violence}
            alt="pegi_descriptors"
            key={pegi_descriptors}
          />
        );
      default:
        return null;
    }
  }

  function getCurrencySymbol() {
    if (currency === "PLN") {
      return "zł";
    } else if (currency === "USD") {
      return "$";
    } else if (currency === "GBP") {
      return "£";
    } else if (currency === "EUR") {
      return "€";
    }
  }

  function setFileName(launcherURL, game_id) {
    let { partner, addId } = launcherURL;
    if (partner === null || partner === undefined) {
      let toEncode = `${game_id}|0|0`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    } else {
      let toEncode = `${game_id}|${partner}|${addId === null ? "0" : addId}`;
      let encoded = Base64.encode(toEncode);
      let toName = encoded.replace(/=/g, "");
      return `primebitstore_setup_${toName}.exe`;
    }
  }

  function checkPriceFormat(price) {
    if (!price.toString().includes(".")) {
      return null
    } else {
      if (price.toString().split(".")[1].length !== 1) {
        return null
      } else {
        return "0"
      }
    }
  }

  return (
    <div className="game mb-5" id="game">
      {/* <Helmet>
        <title>{game.title}</title>
      </Helmet> */}
      <ScrollToTop />
      <section className="game_desc" id="game_desc">
        <div className="container">
          <div className="row">
            <div className="col px-5">
              <img
                src={game.logo}
                alt={game.title}
                className="game_desc-logo img-fluid mx-auto"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Swiper
                id="game_desc-swiper"
                tag="div"
                wrapperTag="ul"
                navigation
                speed={600}
                slidesPerView="auto"
                centeredSlides={true}
                allowTouchMove={true}
                breakpoints={{
                  768: {
                    allowTouchMove: false,
                  },
                }}
              >
                {game.media.map((media) => {
                  return (
                    <SwiperSlide
                      key={media.id}
                      tag="li"
                      style={{ listStyle: "none" }}
                      index={media.id}
                    >
                      <GameDescSlide media={media} title={game.title} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              {game.desktop && (
                <a
                  href={launcherURL.launcherURL}
                  className="nav-link game_desc-btn mt-3"
                  onClick={() => window.gtag("event", "download_launcher")}
                  // download={`PBGL_Setup_.exe`}
                  download={setFileName(launcherURL, game.id)}
                >
                  {game.preorder
                    ? "Pre-order "
                    : lang === "pl"
                    ? "Kup teraz "
                    : "Buy now "}
                  {game.discount !== undefined &&
                    game.discount !== 0 &&
                    game.full_prices && (
                      <span className="game_desc-full_price mr-2 ml-2">
                        {currency !== "PLN" ? getCurrencySymbol() : null}
                        {game.full_prices[`${currency}`]}{checkPriceFormat(game.full_prices[`${currency}`])}&nbsp;
                        {currency === "PLN" ? getCurrencySymbol() : null}
                      </span>
                    )}
                  {game.prices && (
                    <span className="game_desc-price ml-2">
                      {currency !== "PLN" ? getCurrencySymbol() : null}
                      {game.prices[`${currency}`]}{checkPriceFormat(game.prices[`${currency}`])}&nbsp;
                      {currency === "PLN" ? getCurrencySymbol() : null}
                    </span>
                  )}
                </a>
              )}
              {game.discount && game.discount !== 0 ? (
                <span className="game_desc-discount d-inline-block ml-3">
                  {"-" + game.discount + "%"}
                </span>
              ) : null}
              {game.min_prices && (
                <div>
                  {lang === "pl" ? "Najniższa cena z 30 dni:" : "Lowest price in 30 days:"}&nbsp;
                  {currency !== "PLN" ? getCurrencySymbol() : null}
                  {game.min_prices[`${currency}`]}{checkPriceFormat(game.min_prices[`${currency}`])}
                  {currency === "PLN" ? getCurrencySymbol() : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="game_desc" id="game_desc">
        {bundledGames && (
          <div className="row game_desc-bundle">
            <div className="col-12">
              <h2 className="section-heading">
                {lang === "pl"
                  ? "Kup ten produkt a otrzymasz również:"
                  : "Buy this product and you will also recive:"}
              </h2>
            </div>
            <div className="col-12 bundle">
              {bundledGames.map((game) => {
                return (
                  <div className="budle-game" key={game.id}>
                    <img
                      src={game.icon}
                      alt={game.title}
                      className="budle-game-icon"
                    />
                    <h4 className="budle-game-title">{game.title}</h4>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="section-heading mt-3 mb-4">
                {lang === "pl" ? "Opis" : "Description"}
              </h2>
            </div>
          </div>
          <div className="row indent-container">
            <div className="col">
              {!Boolean(getDescription()[0]) && (
                <div dangerouslySetInnerHTML={createMarkup("en")}></div>
              )}
              {Boolean(getDescription()[0]) && (
                <div dangerouslySetInnerHTML={createMarkup()}></div>
              )}
              {Boolean(getDescription()[0]) &&
                (createMarkupExtra().__html !== "" ? (
                  <div dangerouslySetInnerHTML={createMarkupExtra()}></div>
                ) : null)}
            </div>
          </div>
          {!game.google_url &&
          !game.apple_url &&
          !game.nintendo_url &&
          !game.huawei_url &&
          !game.steam_url &&
          !game.gog_url &&
          !game.xbox_url ? null : (
            <>
              <div className="row">
                <div className="col">
                  <h2 className="section-heading my-3">
                    {lang === "pl"
                      ? "Dostępne również na"
                      : "Game available also on"}
                  </h2>
                </div>
              </div>
              <div className="row indent-container">
                <div className="col game_desc-store">
                  {game.google_url && (
                    <a
                      href={game.google_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={google} alt="Google Play" />
                    </a>
                  )}
                  {game.apple_url && (
                    <a
                      href={game.apple_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={apple} alt="Apple Store" />
                    </a>
                  )}
                  {game.huawei_url && (
                    <a
                      href={game.huawei_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={huawei} alt="AppGallery" />
                    </a>
                  )}
                  {game.nintendo_url && (
                    <a
                      href={game.nintendo_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={nintendo} alt="Nintendo Switch" />
                    </a>
                  )}
                  {game.xbox_url && (
                    <a
                      href={game.xbox_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={xbox} alt="XBOX" />
                    </a>
                  )}
                  {game.steam_url && (
                    <a
                      href={game.steam_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={steam} alt="Steam" />
                    </a>
                  )}
                  {game.gog_url && (
                    <a
                      href={game.gog_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={gog} alt="Gog" />
                    </a>
                  )}
                </div>
              </div>
            </>
          )}
          {!game.esrb &&
          !game.pegi &&
          game.pegi_descriptors.length === 0 ? null : (
            <>
              <div className="row">
                <div className="col">
                  <h2 className="section-heading my-3">
                    {lang === "pl" ? "Ograniczenia wiekowe" : "Age Restriction"}
                  </h2>
                </div>
              </div>
              <div className="row indent-container">
                <div className="col game_desc-restrictions">
                  {game.esrb && esrbSwitch(game.esrb)}
                  {game.pegi && pegiSwitch(game.pegi)}
                  {game.pegi_descriptors.length > 0 &&
                    Array.isArray(game.pegi_descriptors) &&
                    game.pegi_descriptors.map((pegi) => pegiDescSwitch(pegi))}
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col">
              <h2 className="section-heading my-3">
                {lang === "pl"
                  ? "Dodatkowe informacje"
                  : "Additional information"}
              </h2>
            </div>
          </div>
          <div className="row indent-container">
            <div className="col game_desc-additional-info">
              <p>
                {lang === "pl" ? "Producent: " : "Developer: "}
                <span className="developer">{game.developer}</span>
              </p>
              <p>
                {lang === "pl" ? "Wydawca: " : "Publisher: "}
                <span className="developer">Prime Bit Games SA</span>
              </p>
              {game.website && (
                <p>
                  {lang === "pl" ? "Witryna internetowa: " : "Website: "}
                  <a
                    href={game.website}
                    className="website"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {game.website}
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Game;
