import React from "react";
import { NavLink } from "react-router-dom";

const Message = ({ lang, games, message }) => {
  const description = message.content.filter((item) => item.lang === lang);

  function createMarkupTitle() {
    return {
      __html: description[0].title,
    };
  }
  return (
    <div className="row message_wrapper">
      <NavLink
        to={{
          pathname: `/news/${message.id}`,
          state: { lang, games, message },
        }}
        className="nav-link message_container"
      >
        <div className="col px-0 message_container-box message_container-box-1">
          <img
            src={message.header}
            alt={message.tag}
            className="message-img img-fluid mb-2"
          />
        </div>
        <div className="col px-0 message_container-box message_container-box-2">
          <h1
            className="message_title"
            dangerouslySetInnerHTML={createMarkupTitle()}
          ></h1>
          <p className="message_created">{message.created}</p>
        </div>
      </NavLink>
    </div>
  );
};

export default Message;
