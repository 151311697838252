import React, { useState, useEffect } from "react";
// import { HashRouter, BrowserRouter, Route, Switch } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.sass";
import Cookies from "universal-cookie";
import CookieConsent from "react-cookie-consent";

import Games from "./pages/Games";
import Game from "./pages/Game";
import News from "./pages/News";
import Error from "./pages/Error";
import NewsRead from "./pages/NewsRead";
import Publish from "./pages/Publish";
import Activate from "./pages/Activate";
// import Register from "./pages/Register";

import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import Loader from "./components/Loader";
import FetchError from "./components/FetchError";

function App() {
  const [lang, setLang] = useState("");
  const [currency, setCurrency] = useState("");

  const [games, setGames] = useState(null);
  const [news, setNews] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [isRegistered, setIsRegistered] = useState(false);
  // const [isPartnerActive, setIsPartnerActive] = useState(false);
  const [launcherURL, setLauncherURL] = useState({
    launcherURL: "",
    partner: "",
    addId: "",
  });

  const cookies = new Cookies();

  useEffect(() => {
    let userLang = navigator.language || navigator.userLanguage;
    if (userLang === "pl" || userLang === "pl-PL" || userLang === "PL") {
      setLang("pl");
    } else {
      setLang("en");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const response_games = await fetch(process.env.REACT_APP_GAMES_URL);
        const games = await response_games.json();
        const response_news = await fetch(process.env.REACT_APP_NEWS_URL);
        const news = await response_news.json();
        setNews(news.news);
        setGames(games.games);
        setIsLoaded(true);
      } catch (error) {
        setError(error);
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (lang === "pl") {
      setCurrency("PLN");
    } else {
      setCurrency("USD");
    }
  }, [lang]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const partner = urlParams.get("p");
    const addId = urlParams.get("id");

    if (window.location.search !== "") {
      cookies.set("args", { partner: partner, addId: addId }, { path: "/" });
      setLauncherURL({
        launcherURL:
          "https://primebitstore.com/installer/PrimeBitGamesLauncher_Setup.exe",
        partner: partner,
        addId: addId,
      });
    } else if (cookies.get("args")) {
      const args = cookies.get("args");
      setLauncherURL({
        launcherURL:
          "https://primebitstore.com/installer/PrimeBitGamesLauncher_Setup.exe",
        partner: args.partner,
        addId: args.addId,
      });
    } else {
      setLauncherURL({
        launcherURL:
          "https://primebitstore.com/installer/PrimeBitGamesLauncher_Setup.exe",
        partner: null,
        addId: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.addEventListener("load", () => {
    const footerHeight = document.querySelector(".footer").offsetHeight;
    const main = document.getElementById("main");
    main.style.paddingBottom = footerHeight + "px";
  });

  window.addEventListener("resize", () => {
    const footerHeight = document.querySelector(".footer").offsetHeight;
    const main = document.getElementById("main");
    main.style.paddingBottom = footerHeight + "px";
  });

  return (
    <BrowserRouter>
      <Navigation
        setLanguage={(value) => setLang(value)}
        lang={lang}
        launcherURL={launcherURL}
        currency={currency}
        setCurrency={(value) => setCurrency(value)}
      />
      <main id="main">
        {isLoading && <Loader />}
        {isError && <FetchError lang={lang} error={error} />}
        {isLoaded && (
          <Switch>
            <Route
              path="/"
              exact
              render={({ ...rest }) => (
                <Games
                  {...rest}
                  lang={lang}
                  games={games}
                  news={news}
                  launcherURL={launcherURL}
                  currency={currency}
                />
              )}
            />
            <Route
              path="/game/:gameTag"
              render={({ ...rest }) => (
                <Game
                  lang={lang}
                  {...rest}
                  launcherURL={launcherURL}
                  currency={currency}
                />
              )}
            />
            <Route
              exact
              path="/news"
              render={({ ...rest }) => (
                <News lang={lang} news={news} games={games} {...rest} />
              )}
            />
            <Route
              exact
              path="/publish"
              render={() => <Publish lang={lang} />}
            />
            <Route
              exact
              path="/aktywuj-kod"
              render={() => <Activate lang={lang} launcherURL={launcherURL} />}
            />
            {/* <Route
              path="/register"
              render={() => <Register checkIsRegistered={(value) => setIsRegistered(value)} currency={currency} checkIsPartnerActive={(value) => setIsPartnerActive(value)} lang={lang} launcherURL={launcherURL} />}
            /> */}
            <Route
              path="/news/:newsID"
              render={({ ...rest }) => <NewsRead lang={lang} {...rest} />}
            />
            <Route render={() => <Error lang={lang} />} />
          </Switch>
        )}
      </main>
      <Footer lang={lang} />
      <CookieConsent
        buttonText={lang === "pl" ? "Zrozumiałem" : "I understood"}
        style={{
          backgroundColor: "#090909",
        }}
        buttonStyle={{ fontFamily: "Open Sans" }}
      >
        <div className="cookie-privacy-content">
          <span className="cookie-privacy-text">
            {lang === "pl"
              ? "Używamy plików cookie, aby pomóc w personalizacji treści, dostosowywać i analizować reklamy oraz zapewnić bezpieczne korzystanie z serwisu. Więcej informacji, łącznie z informacjami o dostępnych opcjach kontroli, znajdziesz w dokumencie: "
              : "We use cookies to help personalize content, customize and analyze ads and ensure the safe use of the website. More information, including information on available control options, refer to the document: "}
          </span>
          <a
            href="https://primebitstore.com/terms/privacy.pdf"
            className="cookie-privacy-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {lang === "pl" ? "Polityka prywtności" : "Privacy Policy"}
          </a>
        </div>
      </CookieConsent>
    </BrowserRouter>
  );
}

export default App;
