import React from "react";
import { Redirect } from "react-router-dom";
import Message from "../components/Message";

const News = (props) => {
  const { lang, games, news } = props;

  if (props.location.state) {
    if (props.location.state.referrer) {
      const message = news.find(
        (message) => message.id.toString() === props.location.state.referrer
      );
      return (
        <Redirect
          to={{
            pathname: `/news/${message.id}`,
            state: {
              lang: { lang },
              message: { message },
              games: { games },
              redirected: true,
            },
          }}
        />
      );
    }
  }

  return (
    <div className="news mb-5" id="news">
      <section className="news_container" id="news_container">
        <div className="container news-wrapper">
          {news.map((message) => (
            <Message
              lang={lang}
              games={games}
              message={message}
              key={message.id}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default News;
