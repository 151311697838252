import React from "react";

import logo from "../images/pbg_logo.png";

const Loader = () => {
  return (
    <div className="loader_container">
      <img src={logo} alt="Prime Bit Games logo" className="loader" />
    </div>
  );
};

export default Loader;
