import React from "react";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import ScrollToTop from "../components/ScrollToTop";

const NewsRead = (props) => {
  if (!props.location.state) {
    const newsID = props.match.params.newsID;
    return (
      <Redirect
        to={{
          pathname: "/news",
          state: { referrer: newsID },
        }}
      />
    );
  }

  let message;
  let lang;

  if (props.location.state.redirected) {
    message = props.location.state.message.message;
    lang = props.location.state.lang.lang;
  } else {
    message = props.location.state.message;
    lang = props.location.state.lang;
  }

  const description = message.content.filter((item) => item.lang === lang);

  function createMarkupTitle() {
    return {
      __html: description[0].title,
    };
  }
  function createMarkupContent() {
    return {
      __html: description[0].text,
    };
  }
  function setIframeHeight() {
    $("iframe").height($("iframe").width() * 0.5625);
  }
  window.addEventListener("resize", () => setIframeHeight());
  setIframeHeight();
  return (
    <div className="news-read mb-5" id="news-read">
      <ScrollToTop />
      <section className="news-read-container" id="news-read-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="news-read_tag my-0 text-uppercase">
                #{message.tag}
              </p>
            </div>
            <div className="col-12">
              <h1
                className="news-read_title"
                dangerouslySetInnerHTML={createMarkupTitle()}
              ></h1>
            </div>
            <div className="col-12">
              <img
                src={message.header}
                alt={message.tag}
                className="news-read_img img-fluid"
              />
            </div>
            <div className="col-12">
              <p className="news-read_created">{message.created}</p>
            </div>
          </div>
          <div className="row">
            <div
              className="col news-read_content"
              dangerouslySetInnerHTML={createMarkupContent()}
            ></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewsRead;
